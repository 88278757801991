import isEqual from 'lodash/isEqual';
import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { closeModal, showSuccessNotification } from 'src/redux/app/appSlice';
import { changeAddress, emptyAddressSuggestions } from 'src/redux/customer/addressSuggestionsSlice';
import {
  CustomerInfoActionTypes,
  EditDeliveryAddress,
  editDeliveryAddressSuccess,
  setSelectedDeliveryAddress,
} from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { editOrderSelector, switchDeliveryAddress } from 'src/redux/editOrder/editOrderSlice';
import { DeliveryAddressType } from 'src/types/customer/address';
import { Modals } from 'src/types/Modals';
import { EditOrderActionRequestWithOrderId, EditOrderActionType } from 'src/types/orderhistory/EditOrderActionRequest';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';
import stringify from 'src/utils/stringify';

import { AddressSuggestionsSaga } from '../addressSuggestions/addressSuggestions';
import { getOfferAndStoreBasket } from '../offer/getOffer';


export function* editDeliveryAddress(action: EditDeliveryAddress) {
  try {
    const editedOrderId = yield select(editOrderSelector.getEditedOrderId);
    const customer = yield select(customerInfoSelectors.getCustomerInfo);
    const selectedCustomerLocalId = customer?.selectedLocalCustomerId;

    if (action.payload.type === DeliveryAddressType.Postal) {
      const isDeliveryAddressValidated = yield select(customerInfoSelectors.deliveryAddressValidated);
      const addressesMatch = isEqual(customer.selectedDeliveryAddress, customer.additionalDeliveryAddress);
      const { id, street, streetNumber, zipCode, city } = action.payload;
      
      yield put(changeAddress({ street, streetNumber, zipCode, city }));
      
      const validAddress = yield call(AddressSuggestionsSaga, action.payload.ignoreAddressCheck === true ?? isDeliveryAddressValidated);
      if (validAddress) {
        if (editedOrderId) {
          const newDeliveryAddress = { ...action.payload, id: id ?? uuidv4() };
          yield put(editDeliveryAddressSuccess(newDeliveryAddress));
          const payload: EditOrderActionRequestWithOrderId = {
            actionRequest: { type: EditOrderActionType.SWITCH_DELIVERY_ADDRESS, address: newDeliveryAddress },
            orderId: editedOrderId,
          };
          yield put(emptyAddressSuggestions());
          yield* closeModalOnSuccess();
          yield put(switchDeliveryAddress(payload));
          yield call(localCustomerStorage.updateLastNewDeliveryAddress, selectedCustomerLocalId, newDeliveryAddress);
        } else {
          yield put(editDeliveryAddressSuccess(action.payload));
          if (addressesMatch) {
            yield put(setSelectedDeliveryAddress(action.payload));
          }
          yield put(emptyAddressSuggestions());
          yield* closeModalOnSuccess();
          yield call(getOfferAndStoreBasket);
          yield call(localCustomerStorage.updateLastNewDeliveryAddress, selectedCustomerLocalId, action.payload);
        }
        yield put(emptyAddressSuggestions());
      }
    } else if (action.payload.type === DeliveryAddressType.Packstation || action.payload.type === DeliveryAddressType.DHLAddress) {
      if (editedOrderId) {
        const newDeliveryAddress = { ...action.payload, id: action.payload.id ?? uuidv4() };
        yield put(editDeliveryAddressSuccess(newDeliveryAddress));
        yield call(localCustomerStorage.updateLastNewDeliveryAddress, selectedCustomerLocalId, newDeliveryAddress);

        const payload: EditOrderActionRequestWithOrderId = {
          actionRequest: { type: EditOrderActionType.SWITCH_DELIVERY_ADDRESS, address: newDeliveryAddress },
          orderId: editedOrderId,
        };
        yield* closeModalOnSuccess();
        yield put(switchDeliveryAddress(payload));
      } else {
        yield put(setSelectedDeliveryAddress(action.payload));
        yield put(editDeliveryAddressSuccess(action.payload));
        yield* closeModalOnSuccess();
        yield call(getOfferAndStoreBasket);
        yield call(localCustomerStorage.updateLastNewDeliveryAddress, selectedCustomerLocalId, action.payload);
      }
    }
  } catch (err) {
    yield call(logErrorAndShowNotification, `Could not edit delivery address ${stringify(action.payload)}`, 'customer.editAddress.error', err);
  }
}

function* closeModalOnSuccess() {
  yield put(closeModal(Modals.address));
  yield put(showSuccessNotification('customer.editAddress.success'));
}

export default function* editDeliveryAddressWatcher() {
  yield takeLatest(CustomerInfoActionTypes.EDIT_DELIVERY_ADDRESS, editDeliveryAddress);
}
