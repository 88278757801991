import {
  DeliveryAddress,
  DeliveryAddressType,
  DHLAddress,
  PackstationDeliveryAddress,
  PostalDeliveryAddress,
  ServicePointType,
} from 'src/types/customer/address';


export const addressToString = (deliveryAddress: DeliveryAddress, intl: (id: string) => string = () => ('')) => {
  if (!deliveryAddress) {
    return '';
  }

  const { street, streetNumber, addressAddition, careOf } = deliveryAddress as PostalDeliveryAddress;
  const { packstationNumber, postNumber, packstation } = deliveryAddress as PackstationDeliveryAddress;
  const { servicePoint } = deliveryAddress as DHLAddress;
  const postNumberMsg = intl('order.edit.postNumber');
  const packstationMsg = intl(`address.create.DHL.${servicePoint?.type ?? ServicePointType.PACKSTATION}`);
  const country = intl(`order.edit.deliveryAddress.Country.${deliveryAddress.countryCode}`);

  const streetInfo = `${street || `${postNumberMsg} ${postNumber || servicePoint?.post},`} ${streetNumber || `${packstationMsg} ${packstationNumber ?? packstation ?? servicePoint?.number}`}`;
  return `${deliveryAddress.firstName} ${deliveryAddress.lastName}, ${careOf ? `C/O ${careOf}, ` : ''}${streetInfo}, ${deliveryAddress.zipCode}, ${addressAddition ? `${addressAddition}, ` : ''}${deliveryAddress.city ? `${deliveryAddress.city}, ` : ''}${country}`;
};

export function containsSimilarAddress(deliveryAddresses: DeliveryAddress[] | undefined, deliveryAddress: DeliveryAddress): boolean {
  const deliveryAddressAsString = addressToString(deliveryAddress);
  return !!deliveryAddresses?.find(it => addressToString(it) === deliveryAddressAsString);
}

export const addressToStringWithoutFLName = (deliveryAddress?: DeliveryAddress, intl: (id: string) => string = () => ('')) => {
  if (!deliveryAddress) {
    return '';
  }

  const [street, streetNr] = deliveryAddress.type === DeliveryAddressType.Postal ? [deliveryAddress.street, deliveryAddress.streetNumber] : [];
  const [packstationNumber, postNumber] = deliveryAddress.type === DeliveryAddressType.Packstation ? [deliveryAddress.packstationNumber, deliveryAddress.postNumber] : [];

  return `${street || packstationNumber} ${streetNr || postNumber}, ${deliveryAddress.zipCode}, ${deliveryAddress.city}, ${intl(`order.edit.deliveryAddress.Country.${deliveryAddress.countryCode}`)}`;
};

