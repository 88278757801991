import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { emptyAddressSuggestions } from 'src/redux/customer/addressSuggestionsSlice';
import { logoutCustomer } from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { resetCustomerSaga } from 'src/sagas/customer/resetCustomer';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import { removeReklaOrderInfoFromSession } from 'src/utils/reklaOrderUtils';


export function* logoutCustomerSaga() {
  try {
    const localCustomerId = yield select(customerInfoSelectors.getSelectedCustomerLocalId);
    localCustomerStorage.removeLastNewDeliveryAddress(localCustomerId);
    removeReklaOrderInfoFromSession();
    yield put(emptyAddressSuggestions());
    yield call(resetCustomerSaga);
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not do logout customer', err }));
  }
}

export default function* logoutCustomerWatcher() {
  yield takeEvery(logoutCustomer.type, logoutCustomerSaga);
}
