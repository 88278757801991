import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { closeModal } from 'src/redux/app/appSlice';
import { changeAddress, emptyAddressSuggestions } from 'src/redux/customer/addressSuggestionsSlice';
import {
  createCustomerError,
  CreateCustomerWithDuplicatedCheck,
  createCustomerWithDuplicatedCheck,
} from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { customerSuggestionsSelector } from 'src/redux/customer/selectors/customerSuggestionsSelectors';
import { checkCustomer } from 'src/sagas/customer/CheckCustomerByNameAndBirthday';
import { createCustomerSuccessfullySaga } from 'src/sagas/customer/createCustomer';
import { Modals } from 'src/types/Modals';

import { AddressSuggestionsSaga } from '../addressSuggestions/addressSuggestions';


export function* createCustomerWithDuplicatedCheckSaga(action: CreateCustomerWithDuplicatedCheck) {
  try {
    const { billingAddressValidated } = customerInfoSelectors;
    const isBillingAddressValidated = yield select(billingAddressValidated);
    const {
      billingAddress,
    } = action.payload;
    if (billingAddress) {
      yield put(changeAddress(billingAddress));
    }

    const validAddress = yield call(AddressSuggestionsSaga, action.payload.ignoreAddressCheck === true ?? isBillingAddressValidated);

    if (validAddress) {

      yield call(checkCustomer, action.payload);
      const suggestionCustomers = yield select(customerSuggestionsSelector.getSuggestionCustomers);

      if (suggestionCustomers === undefined) {
        yield call(createCustomerSuccessfullySaga, action.payload);
      } else {
        yield put(closeModal(Modals.customer));
        yield put(emptyAddressSuggestions());
      }
    }
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not create customer [with duplicate check]', err }));
    const errorCode = (err?.response?.status) || 400;
    yield put(createCustomerError(errorCode));
  }
}

export default function* createCustomerWithDuplicatedCheckWatcher() {
  yield takeLatest(createCustomerWithDuplicatedCheck.type, createCustomerWithDuplicatedCheckSaga);
}
