import { call, put } from 'redux-saga/effects';

import config from 'src/config';
import {
  LoginMethod,
  openModal,
  setLoginMethod
} from 'src/redux/app/appSlice';
import {
  birthDayCheckConfirmed,
  createCustomerSuccess,
  fetchCustomerSuccess,
  setBirthDayCheckConfirmed
} from 'src/redux/customer/customerInfoSlice';
import {
  authenticateCustomer,
  resetCustomerPinState,
  setPinCheckModalNextStep
} from 'src/redux/customer/CustomerPinSlice';
import {
  addOriginalOrderErrors,
  FailedRecreateOrderStep,
  RecreateOrderError,
} from 'src/redux/recreateOrder/recreateOrderSlice';
import { findCustomerByExternalId } from 'src/sagas/customer/fetchCustomerByNumberSaga';
import { handleBlockedCustomer } from 'src/sagas/customer/handleBlockedCustomer';
import { processAndGetOffer } from 'src/sagas/offer/getOffer';
import { Customer } from 'src/types/customer/customer';
import { CustomerResponse } from 'src/types/customer/CustomerResponse';
import { Modals } from 'src/types/Modals';
import { extractDetails } from 'src/utils/errorStatusChecks';
import { isBirthdayCheckConfirmed } from 'src/utils/lastBirthdayCheck';
import { reformatBirthdayInResponse } from 'src/utils/mappers/reformatBirthdayInResponse';
import isCustomerLocked from 'src/utils/validators/isCustomerLocked';


export function* fillOriginalOrderCustomer(customer: CustomerResponse) {
  if (customer.crmPartnerId) {
    yield call(loadExistingOrderCustomer, customer.crmPartnerId, false);
    return;
  }

  try {
    const newCustomer: Customer = {
      firstName: customer.firstName,
      lastName: customer.lastName,
      billingAddress: {
        zipCode: customer.zipCode,
        street: customer.street,
        streetNumber: customer.streetNumber,
        countryCode: customer.countryCode,
        city: customer.city,
        careOf: customer.careOf,
      },
      salutation: customer.salutation,
      dateOfBirth: customer.dateOfBirth,
      id: '',
      externalCustomerId: '',
      phoneNumber: customer.phone,
      phoneNumbers: [],
      deliveryAddresses: [],
      origin: '',
      consents: customer.consents,
    };
    yield put(createCustomerSuccess(newCustomer));
    yield put(setBirthDayCheckConfirmed());

    yield call(processAndGetOffer);
  } catch (err) {
    const error: RecreateOrderError = {
      step: FailedRecreateOrderStep.LOAD_CUSTOMER,
      detail: `Neukunde konnte nicht geladen werden: ${extractDetails(err)}`,
    };
    yield put(addOriginalOrderErrors(error));
  }
}

export function* loadExistingOrderCustomer(externalCustomerId: string, skipAuthenticationCheck: boolean) {
  try {
    const existingCustomer = yield call(findCustomerByExternalId, externalCustomerId);
    if (isCustomerLocked(existingCustomer)) {
      yield call(handleBlockedCustomer, existingCustomer);
      return;
    }
    const customer = reformatBirthdayInResponse(existingCustomer);
    yield put(resetCustomerPinState(customer));
    yield put(fetchCustomerSuccess(customer));
    yield put(setBirthDayCheckConfirmed()); // TODO: should make CustomerInfo final to avoid this needless dependencies
    if (skipAuthenticationCheck) {
      yield put(authenticateCustomer());
    } else {
      yield put(setLoginMethod(LoginMethod.LOAD_EXISTING_ORDER_CUSTOMER));
      if (existingCustomer.pinChallenge !== undefined && config.env.enableCustomerPinFeature) {
        yield put(setPinCheckModalNextStep());
        yield put(openModal(Modals.pinChallengeModal));
      } else {
        if(isBirthdayCheckConfirmed(customer.externalCustomerId)){
          yield put(birthDayCheckConfirmed());
        } else {
          yield put(openModal(Modals.birthdayCheck));
        }
      }
    }

    yield call(processAndGetOffer);
  } catch (err) {
    const error: RecreateOrderError = {
      step: FailedRecreateOrderStep.LOAD_CUSTOMER,
      detail: `Kunde ${externalCustomerId} konnte nicht authentifiziert werden: ${extractDetails(err)}`,
    };
    yield put(addOriginalOrderErrors(error));
  }
}
