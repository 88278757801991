import { put } from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { storeSelectedDeliveryAddressOrUseBilling } from 'src/redux/customer/customerInfoSlice';
import { Customer } from 'src/types/customer/customer';


export function* findAndSelectDefaultDeliveryAddress(customer: Customer) {
  try {
    const defaultAddress = customer.deliveryAddresses.find((address) => address.isDefault)
      ?? customer.deliveryAddresses.find((address) => address.externalAddressId === customer.externalCustomerId);
    yield put(storeSelectedDeliveryAddressOrUseBilling(defaultAddress));
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not select default delivery address for customer ${customer?.id}`, err }));
  }
}
