import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal } from 'src/redux/app/appSlice';
import { checkCustomerEmail, CheckCustomerEmail } from 'src/redux/customer/customerInfoSlice';
import {
  fetchCustomerByEmailStart,
  fetchCustomerByEmailSuccess,
  setCustomerCreateFormValues,
  fetchCustomerByEmailEnds,
} from 'src/redux/customer/customerSuggestionsSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Customer } from 'src/types/customer/customer';
import { Modals } from 'src/types/Modals';
import { isNotFoundStatus } from 'src/utils/errorStatusChecks';


export function* checkCustomerEmailSaga(action: CheckCustomerEmail) {
  try {
    yield put(fetchCustomerByEmailStart());
    const customerApi: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
    const email = action.payload?.email ?? '';
    const response: Customer = yield call(customerApi.fetchCustomerByEmail, email);

    if (response) {
      yield put(fetchCustomerByEmailSuccess(response));
      yield put(setCustomerCreateFormValues(action.payload));
      if (response.isHelloUser) {
        return;
      }
      yield put(openModal(Modals.duplicatedEmail));
    }
  } catch (err) {
    yield put(fetchCustomerByEmailEnds());
    if(!isNotFoundStatus(err)) {
      yield put(logErrorEvent({ message: 'Could not check customer by email', err }));
    }
  }
}


export function* checkCustomerEmailWatcher() {
  yield takeLatest(checkCustomerEmail.type, checkCustomerEmailSaga);
}
