export default {
  'orderentry.basket.error': 'Fehler beim Abrufen der Warenkorbartikel',
  'orderentry.orderEntry': 'Auftragserfassung',
  'orderentry.productId': 'Bestellnummer',
  'orderentry.quantity': 'Anzahl',
  'orderentry.description': 'Bezeichnung',
  'orderentry.price': 'Einzelpreis',
  'orderentry.priceDate': 'Pos. Preisdatum',
  'orderentry.wrongDate': 'Bitte Datum prüfen',
  'orderentry.currency': 'Währung',
  'orderentry.totalPrice': 'Gesamtpreis',
  'orderentry.upsells': 'Mögliche Upsells',
  'orderentry.Strornogrund': 'Strornogrund',
  'orderentry.Preisdatum': 'Pos. Preisdatum',
  'orderentry.position.priceDate': 'Preisdatum',
  'orderentry.transport': 'Transport',
  'orderentry.liefertage': 'Liefertage',
  'orderentry.Liefadresse': 'Lieferadresse',
  'orderentry.deliveryTime': 'Liefertage',
  'fourteenDaysDelay': 'Spedition - Telefonisch Vereinbarung in <b>ca. 14 Tagen</b>',
  'daysDeliveryTime': 'ca.',
  'standardDeliveryTime': '3-5 Tage',
  'days': 'Werktage',
  'orderentry.productAdded.VERY_NICE_CHOICE': 'Sehr schöne Wahl.',
  'orderentry.productAdded.GOOD_CHOICE': 'Gute Auswahl.',
  'orderentry.productAdded.VERY_POPULAR_TODAY': 'Ganz beliebt heute.',
  'orderentry.productAdded.BESTSELLERS_WITH_US': 'Bestseller heute bei uns.',
  'orderentry.productAdded.WONDERFUL_DECISION': 'Wunderbare Entscheidung getroffen.',
  'orderentry.productAdded.EXCELLENT_CHOICE': 'Ausgezeichnete Auswahl haben Sie getroffen.',
  'orderentry.hideOffers': 'Angebote ausblenden',
  'orderentry.showOffers': 'Angebote anzeigen',
  'orderentry.tv': 'TV',
  'orderentry.topDeals': 'Top Deals',
  'orderentry.addToBasket': 'In den Warenkorb',
  'orderentry.displayVariants': 'Weitere Varianten',
  'orderEntry.inputSearch': 'Bestellnr. eingeben, Enter drücken',
  'orderEntry.recreatedOrderHint' : 'Ursprüngliche Bestellung: {orderId}',
  'orderEntry.recreatedOrderPlaceholder' : 'Internet ANR',
  'orderentry.crossSells': 'Produktcrosssells',
  'orderentry.adtCrossSells': 'Zeitcrosssells',
  'orderentry.productNotSellable': 'Produkt ist nicht verkaufsfähig',
  'orderentry.freeShipping.FOLLOWUP_ORDER': 'Folgeauftrag',
  'orderentry.freeShipping.FREE_SHIPPING_ARTICLE': 'Produkt',
  'orderentry.freeShipping.HAPPY_HOUR': 'Aktion',
  'orderentry.freeShipping.SERVICE_VOUCHER': 'Service Gutschein',
  'orderentry.freeShipping.FREE_SHIPPING_CAMPAIGN': 'Kostenlose Versandaktion',
  'orderentry.freeShipping.FREE_SHIPPING_VOUCHER': 'kostenloser Versandgutschein',
  'orderentry.phoneBot.enterCode': 'Phonebot-Code eingeben, Enter drücken',
  'orderHistory.fetchByLimelightId.failed': 'Bestellung konnte nicht abgerufen werden',
  'orderentry.basket.empty': 'Ihr Warenkorb ist derzeit leer',
  'orderentry.basket.emptyText': 'Verwenden Sie die Suchleiste, um das Gesuchte zu finden und in den Warenkorb zu legen.',
  'orderEntry.sapNumberSearch.placeholder': 'SAP-Auftragsnummer',
  'orderEntry.sapNumberSearch.copy': 'Kopieren',
  'orderEntry.availability.nonSocialLiveItem' : 'Produkt ist kein HSE Hello-Showprodukt. Bitte aus Warenkorb entfernen.',
};
