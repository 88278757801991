import {
  call,
  getContext,
  put,
  select,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { newOrderSelector } from 'src/redux/order/selectors/newOrderSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Customer, MarketResearchConsent } from 'src/types/customer/customer';
import { SetGuestMarketResearchRequest } from 'src/types/customer/UpdateMarketResearchRequest';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';


function* handleGuestCustomerConsents() {
  const { consents, email, phoneNumber, externalCustomerId }: Customer = yield select(customerInfoSelectors.getCustomer);
  if(externalCustomerId) {
    return;
  }

  const orderId: string = yield select(newOrderSelector.getOrderId);
  const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);

  const request: SetGuestMarketResearchRequest = {
    orderId: orderId,
    phoneMarketResearchConsent: inferMarketResearchConsent(consents?.phoneMarketResearch),
    phoneNumber: trimStringOrNull(phoneNumber),
    emailMarketResearchConsent: inferMarketResearchConsent(consents?.emailMarketResearch),
    email: trimStringOrNull(email),
  };

  try {
    const localCustomerId = yield select(customerInfoSelectors.getSelectedCustomerLocalId);

    const savedCustomer = localCustomerStorage.findLastNewCustomer(localCustomerId);
    if (savedCustomer) {
      const { wasAskedAboutConsents, guestCustomerConsentsStepIsSkipped } = savedCustomer;

      if(
        guestCustomerConsentsStepIsSkipped &&
        request.emailMarketResearchConsent === MarketResearchConsent.NOT_QUERIED &&
        request.phoneMarketResearchConsent === MarketResearchConsent.NOT_QUERIED
      ) {
        yield put(logEvent({
          message: `Agent skipped the guest customer consents step: ${guestCustomerConsentsStepIsSkipped ?? false}, alreadyAskedAboutConsents: ${wasAskedAboutConsents}`,
          level: LOG_LEVEL.INFO },
        ));
        return;
      }
    }

    yield call(apiCustomer.setGuestMarketResearchConsent, request);
  } catch (error) {
    delete request.phoneNumber;
    yield call(
      logErrorAndShowNotification,
      `Cannot handleGuestCustomerConsents with request: ${JSON.stringify(request)}`,
      'Hoppla... Zustimmungen für diesen Kunden konnten nicht gespeichert werden',
      error,
    );
  }
}

function inferMarketResearchConsent(value?: boolean) {
  return value === undefined ? MarketResearchConsent.NOT_QUERIED :
    (value ? MarketResearchConsent.ALLOWED : MarketResearchConsent.NOT_ALLOWED);
}

export default handleGuestCustomerConsents;
