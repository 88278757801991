import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { closeModal, openModal } from 'src/redux/app/appSlice';
import {
  addDeliveryAddressSuccess,
  birthDayCheckConfirmed,
  fetchCustomerFinished,
  fetchCustomerInProgress,
  fetchCustomerSuccess,
  setPreviouslyUsedBankAccount,
  setSelectedCustomerLocalId,
  setSelectedPreferredPayment,
  storeSelectedDeliveryAddressOrUseBilling
} from 'src/redux/customer/customerInfoSlice';
import { checkCustomerPinSuccessful, skipPinChallengeSuccess } from 'src/redux/customer/CustomerPinSlice';
import { resetCustomers } from 'src/redux/customer/customerSearchSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { customerPinSelector } from 'src/redux/customer/selectors/customerPinSelectors';
import { getOffer } from 'src/redux/order/orderEntrySlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { resetVoucher } from 'src/redux/order/voucherSlice';
import {
  DEFAULT_PAYMENTS,
  getBankingDetailsSuccess,
  resetInstallmentPayment,
  resetPayment,
  resetSelectedPaymentMethod,
  storeAvailablePaymentMethods,
  storeSelectedPaymentMethod,
} from 'src/redux/payment/paymentSlice';
import {
  checkAndOpenReceptionSurvey,
  isConsentNotYetQueried,
  loadAlreadyManagedConsents,
} from 'src/sagas/customer/authenticateCustomerUtils';
import { findAndSelectDefaultDeliveryAddress } from 'src/sagas/customer/setSelectedAddress';
import { DeliveryAddress } from 'src/types/customer/address';
import { Customer, MarketResearchConsent } from 'src/types/customer/customer';
import { ReceptionProvider } from 'src/types/customer/ReceptionSurveyQuestionsData';
import { Modals } from 'src/types/Modals';
import { CustomerType } from 'src/types/voucher/EmployeeDiscount';
import { containsSimilarAddress } from 'src/utils/formatters/formatAddressToString';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import { reformatBirthdayInResponse } from 'src/utils/mappers/reformatBirthdayInResponse';

import { shouldStorePreferredPaymentMethod } from './fetchCustomerUtils';


export function* loadLoggedInCustomerSaga(action?: PayloadAction) {
  const loadedCustomer: Customer = yield select(customerPinSelector.getPreLoadedCustomer);
  try {
    const isRekla = yield select(orderEntrySelector.isReklaOrder);
    yield put(fetchCustomerInProgress());
    const nextStep = yield select(customerPinSelector.getNextStep);

    window.sessionStorage.removeItem('customerId');

    const customer = yield call(checkAndLoadLocalCustomerInfo, loadedCustomer);

    if (!isRekla) {
      yield put(resetSelectedPaymentMethod());
    }
    yield put(resetInstallmentPayment());
    yield put(storeAvailablePaymentMethods(DEFAULT_PAYMENTS));
    if (!isRekla) {
      yield call(findAndSelectDefaultDeliveryAddress, customer);
    }
    yield put(fetchCustomerSuccess(reformatBirthdayInResponse(customer)));

    if (customer.bankingDetails) {
      yield put(getBankingDetailsSuccess({ bankingDetails: customer.bankingDetails }));
      yield put(setPreviouslyUsedBankAccount());
    } else {
      yield put(resetPayment());
    }

    const preferredAddress = customer.deliveryAddresses?.find((a: DeliveryAddress) => a.isDefault);
    storeSelectedDeliveryAddressOrUseBilling(preferredAddress);

    if (!isRekla) {
      const goodPreferredPayment = shouldStorePreferredPaymentMethod(customer) ? customer.preferredPaymentMethod : undefined;
      yield put(setSelectedPreferredPayment(goodPreferredPayment));
      yield put(storeSelectedPaymentMethod(goodPreferredPayment));
    }

    if (customer?.type && customer.type !== CustomerType.REGULAR) {
      yield put(resetVoucher());
    } else {
      yield put(getOffer());
    }

    // TODO: centralize locally stored info in one place ;-> localCustomerStorage
    yield call(loadAlreadyManagedConsents, customer);

    if(nextStep === Modals.consentManagement) {
      const consentNotAllowed = customer.emailMarketResearchConsent === MarketResearchConsent.NOT_ALLOWED
        || customer.marketResearchConsent === MarketResearchConsent.NOT_ALLOWED;
      if (isConsentNotYetQueried(customer.marketResearchConsent, consentNotAllowed)) {
        yield put(openModal(nextStep));
      } else {
        yield call(checkAndOpenReceptionSurvey, customer);
      }
    }

    yield put(resetCustomers());
    yield put(closeModal(Modals.customerSearch));
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not load logged in customer ${loadedCustomer?.id}, action call: ${action?.type}`, err }));
  } finally {
    yield put(fetchCustomerFinished());

    const shouldMOveToOrderHistoryPage: boolean | undefined = yield select(customerInfoSelectors.getShouldMOveToOrderHistoryPageValue);

    if (shouldMOveToOrderHistoryPage === true){
      window.sessionStorage.setItem('customerId', loadedCustomer.externalCustomerId);
      yield put(push(`/orderhistory/${loadedCustomer.externalCustomerId}`));
    }
  }
}

export function* checkAndLoadLocalCustomerInfo(customer: Customer) {
  const updatedCustomer = { ...customer };
  const savedCustomer = localCustomerStorage.getLastCustomerByExternalId(updatedCustomer.externalCustomerId);
  if(!savedCustomer) {
    const localCustomerId = localCustomerStorage.saveCustomer(updatedCustomer);
    yield put(setSelectedCustomerLocalId(localCustomerId));
    return updatedCustomer;
  }

  yield put(setSelectedCustomerLocalId(savedCustomer.id));

  if (!updatedCustomer.dateOfBirth && savedCustomer.customer?.dateOfBirth) {
    updatedCustomer.dateOfBirth = savedCustomer.customer.dateOfBirth;
  }

  if (updatedCustomer.reception === undefined || updatedCustomer.reception === ReceptionProvider.NOT_ASKED) {
    updatedCustomer.reception = savedCustomer.reception;
  }

  if (containsSimilarAddress(updatedCustomer.deliveryAddresses, savedCustomer.deliveryAddress!)) {
    localCustomerStorage.removeLastNewDeliveryAddress(savedCustomer.id);
  } else {
    yield put(addDeliveryAddressSuccess(savedCustomer.deliveryAddress));
  }

  return updatedCustomer;
}

export default function* fetchCustomerWatcher() {
  yield takeLatest(checkCustomerPinSuccessful.type, loadLoggedInCustomerSaga);
  yield takeLatest(skipPinChallengeSuccess.type, loadLoggedInCustomerSaga);
  yield takeLatest(birthDayCheckConfirmed.type, loadLoggedInCustomerSaga);
}
