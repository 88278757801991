import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { closeModal, showSuccessNotification } from 'src/redux/app/appSlice';
import { changeAddress, emptyAddressSuggestions } from 'src/redux/customer/addressSuggestionsSlice';
import {
  AddDeliveryAddress,
  addDeliveryAddressSuccess,
  CustomerInfoActionTypes,
  setSelectedDeliveryAddress,
} from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { switchDeliveryAddress, editOrderSelector } from 'src/redux/editOrder/editOrderSlice';
import { DeliveryAddressType } from 'src/types/customer/address';
import { Modals } from 'src/types/Modals';
import { EditOrderActionRequestWithOrderId, EditOrderActionType } from 'src/types/orderhistory/EditOrderActionRequest';
import localCustomerStorage  from 'src/utils/localCustomerStorage';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';
import stringify from 'src/utils/stringify';

import { AddressSuggestionsSaga } from '../addressSuggestions/addressSuggestions';
import { getOfferAndStoreBasket } from '../offer/getOffer';


export function* addDeliveryAddress(action: AddDeliveryAddress) {
  try {
    const selectedCustomerLocalId = yield select(customerInfoSelectors.getSelectedCustomerLocalId);
    const editedOrderId = yield select(editOrderSelector.getEditedOrderId);
    if (action.payload.type === DeliveryAddressType.Postal) {
      const isDeliveryAddressValidated = yield select(customerInfoSelectors.deliveryAddressValidated);
      const {
        street,
        streetNumber,
        zipCode,
        city,
      } = action.payload;
      yield put(changeAddress({ street, streetNumber, zipCode, city }));
      const validAddress = yield call(AddressSuggestionsSaga, action.payload.ignoreAddressCheck === true ?? isDeliveryAddressValidated);
      if (validAddress) {
        if (editedOrderId) {
          const phoneNumber = action.payload?.phoneNumbers ? action.payload?.phoneNumbers[0]?.value : undefined;
          const newDeliveryAddress = { ...action.payload, id: uuidv4(), phoneNumber: phoneNumber };
          yield put(addDeliveryAddressSuccess(newDeliveryAddress));
          const payload: EditOrderActionRequestWithOrderId = {
            actionRequest: { type: EditOrderActionType.SWITCH_DELIVERY_ADDRESS, address: newDeliveryAddress },
            orderId: editedOrderId,
          };
          yield put(emptyAddressSuggestions());
          yield* closeModalOnSuccess();
          yield put(switchDeliveryAddress(payload));
          yield call(localCustomerStorage.updateLastNewDeliveryAddress, selectedCustomerLocalId, newDeliveryAddress);
        } else {
          yield put(setSelectedDeliveryAddress(action.payload));
          yield put(addDeliveryAddressSuccess(action.payload));
          yield call(localCustomerStorage.updateLastNewDeliveryAddress, selectedCustomerLocalId, action.payload);
          yield put(emptyAddressSuggestions());
          yield* closeModalOnSuccess();
          yield call(getOfferAndStoreBasket);
        }
        yield put(emptyAddressSuggestions());
      }
    } else if (action.payload.type === DeliveryAddressType.Packstation || action.payload.type === DeliveryAddressType.DHLAddress) {
      if (editedOrderId) {
        const newDeliveryAddress = { ...action.payload, id: uuidv4() };
        yield put(addDeliveryAddressSuccess(newDeliveryAddress));

        const payload: EditOrderActionRequestWithOrderId = {
          actionRequest: { type: EditOrderActionType.SWITCH_DELIVERY_ADDRESS, address: newDeliveryAddress },
          orderId: editedOrderId,
        };
        yield* closeModalOnSuccess();
        yield put(switchDeliveryAddress(payload));
        yield call(localCustomerStorage.updateLastNewDeliveryAddress, selectedCustomerLocalId, newDeliveryAddress);
      } else {
        yield put(setSelectedDeliveryAddress(action.payload));
        yield put(addDeliveryAddressSuccess(action.payload));
        yield call(localCustomerStorage.updateLastNewDeliveryAddress, selectedCustomerLocalId, action.payload);
        yield* closeModalOnSuccess();
        yield call(getOfferAndStoreBasket);
      }
    }
  } catch (err) {
    yield call(logErrorAndShowNotification, `Could not add delivery address ${stringify(action.payload)}`, 'customer.addAddress.error', err);
  }
}

function* closeModalOnSuccess() {
  yield put(closeModal(Modals.address));
  yield put(showSuccessNotification('customer.addAddress.success'));
}

export default function* addDeliveryAddressWatcher() {
  yield takeLatest(CustomerInfoActionTypes.ADD_DELIVERY_ADDRESS, addDeliveryAddress);
}
