import { isDate } from 'date-fns';
import dayjs from 'dayjs';
import {
  string,
  StringSchema,
  date as yupDate,
} from 'yup';

import { CountryCode } from 'src/api/orderTypes';
import { DEFAULT_DISPLAY_DATE_FORMAT } from 'src/constants/date';
import { ServicePointType } from 'src/types/customer/address';
import isPackstationPostNumberValid from 'src/utils/validators/isPackstationPostNumberValid';


export const PHONE_NUMBER_REGEX = /^0[1-9][0-9]{5,}$/;
export const ZIP_CODE_REGEX = /^[0-9]{4,5}$/;
export const AT_ZIP_CODE_REGEX = /^[0-9]{4}$/;
export const DE_ZIP_CODE_REGEX = /^[0-9]{5}$/;
export const INVALID_ADDRESS_CHARACTERS_REGEX = /[„“#$%‚‘*:;<>=?[\]\\^{|}~]/;
export const NOT_EMAIL_REGEX = /^((?!@).)*$/;
export const INVALID_EMAIL_CHARACTERS_REGEX = /[äöüÄÖÜß]/;
// eslint-disable-next-line no-useless-escape
export const CRM_EMAIL_REGEX = /^(?!^.{81})([a-zA-Z0-9_\+\-])+([\.][a-zA-Z0-9_\+\-]+)*@[a-zA-Z0-9_](([a-zA-Z0-9_\-])*[a-zA-Z0-9_])?(\.[a-zA-Z0-9_](([a-zA-Z0-9_\-])*[a-zA-Z0-9_])?)*\.[A-Za-z]{2,63}$/;
export const SHOULD_START_WITH_NUMBER_REGEX = /^\d\S*$/g;

export const DATE_REGEX = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:\d{4})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:\d{4})$/;
const PACKSTATION_REGEX = /^\d+$/;
const PACKSTATION_STREET_REGEX = /pac?k\s*?station/i;
const POSTFILIALE_STREET_REGEX = /pos?t\s*?filiale/i;
const POSTSTATION_STREET_REGEX = /pos?t\s*?station/i;
const PAKETSHOP_STREET_REGEX = /pake?t\s*?shop/i;
const POST_NUMBER_REGEX = /pos?t\s*?nummer/i;
const NOT_LETTERS_REGEX = /[^a-zA-Z]/g;

const eighteenYearsAgo = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export const phoneNumberSchema = (message = '', required = false): StringSchema => {
  if (required) {
    return string()
      .matches(PHONE_NUMBER_REGEX, {
        message: message,
        excludeEmptyString: true,
      })
      .required(message);
  }
  return string().matches(PHONE_NUMBER_REGEX, {
    message: message,
    excludeEmptyString: true,
  });
};

export const zipCodeSchema = (message?: string, country?: CountryCode): StringSchema => {
  return string().matches(
    country ?
      country === CountryCode.at ? AT_ZIP_CODE_REGEX : DE_ZIP_CODE_REGEX
      :
      ZIP_CODE_REGEX,
    { message: message },
  );
};

export const testInvalidChars = (message?: string) => ({
  name: 'invalidChars',
  message,
  test: (value = '') => !INVALID_ADDRESS_CHARACTERS_REGEX.test(value),
});

export const testStreetLength = (message?: string) => ({
  name: 'invalidLength',
  message,
  test: (value = '') => value.replace(NOT_LETTERS_REGEX, '').length >= 3,
});

export const testPackstationNumberLength = (message?: string) => ({
  name: 'invalidLength',
  message,
  test: (value = '') => value.length <= 3,
});

export const testPostNumberValidity = (message?: string) => ({
  name: 'invalidPostNumber',
  message,
  test: (value = '') => isPackstationPostNumberValid(value),
});

export const birthDateSchema = (message = '') =>
  string()
    .matches(DATE_REGEX, message)
    .test('dateRange', message, (value = '') => {
      if (!DATE_REGEX.test(value)) {
        return true;
      }
      const [day, month, year] = value.split('.').map(datePart => parseInt(datePart, 10));
      const birthDate = new Date(year, month - 1, day);
      return birthDate < eighteenYearsAgo() && year >= 1900;
    });

export const dateFormatSchema = (message = '') =>
  string()
    .matches(DATE_REGEX, message)
    .test('dateRange', message, (value = '') => {
      if (!DATE_REGEX.test(value)) {
        return true;
      }
      return true;
    });

export const packstationSchema = (message = '') => {
  return string().matches(PACKSTATION_REGEX, { message: message });
};

export const isPackstationStreet = (value: string): boolean => PACKSTATION_STREET_REGEX.test(value);
export const isPostfilialeStreet = (value: string): boolean => POSTFILIALE_STREET_REGEX.test(value);
export const isPoststationStreet = (value: string): boolean => POSTSTATION_STREET_REGEX.test(value);
export const isPaketshopStreet = (value: string): boolean => PAKETSHOP_STREET_REGEX.test(value);

export const isDHLStreet = (value: string): ServicePointType | undefined => {
  if (isPackstationStreet(value)) {
    return ServicePointType.PACKSTATION;
  } else if (isPostfilialeStreet(value)) {
    return ServicePointType.POSTFILIALE;
  } else if (isPoststationStreet(value)) {
    return ServicePointType.POSTSTATION;
  } else if (isPaketshopStreet(value)) {
    return ServicePointType.PAKETSHOP;
  }
  return undefined;
};

export const testPackstationStreet = (message?: string) => ({
  name: 'packstationsStreet',
  message,
  test: (value = '') => !PACKSTATION_STREET_REGEX.test(value),
});

export const testPostNumber = (message?: string) => ({
  name: 'postNumber',
  message,
  test: (value = '') => !POST_NUMBER_REGEX.test(value),
});


const parseDateString = (value: string, originalValue: string) => {
  return isDate(originalValue) ? originalValue : dayjs(originalValue, DEFAULT_DISPLAY_DATE_FORMAT)?.toDate();
};
export const yupValidateDate = (invalidDateMessage: string, max: Date) => yupDate()
  .transform(parseDateString)
  .typeError(invalidDateMessage)
  .max(max, invalidDateMessage);

export const testInvalidEmailChars = (message?: string) => ({
  name: 'invalidEmailChars',
  message,
  test: (value = '') => !INVALID_EMAIL_CHARACTERS_REGEX.test(value),
});

export const isValidEmail = (message?: string) => ({
  name: 'invalidEmail',
  message,
  test: (value = '') => value === '' || value.match(CRM_EMAIL_REGEX) !== null,
});
