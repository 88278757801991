import { PayloadAction } from '@reduxjs/toolkit';
import { Exception } from '@sentry/browser';
import {
  call,
  getContext,
  put,
  select,
  takeLatest
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import config from 'src/config';
import { logErrorEvent } from 'src/logging/loggingActions';
import {
  ErrorContext,
  LoginEventPayload,
  LoginMethod,
  openModal,
  setLoginMethod,
  trackUserFailureLoginsEvent,
  trackUserLoginEvent
} from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import {
  birthDayCheckConfirmed,
  customerError,
  fetchCustomer,
  fetchCustomerFinished,
  fetchCustomerForWaitlist,
  fetchCustomerInProgress
} from 'src/redux/customer/customerInfoSlice';
import { resetCustomerPinState, setPinCheckModalNextStep } from 'src/redux/customer/CustomerPinSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { checkAndLoadLocalCustomerInfo } from 'src/sagas/customer/fetchCustomer';
import { handleBlockedCustomer } from 'src/sagas/customer/handleBlockedCustomer';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { DeliveryAddress, DeliveryAddressType } from 'src/types/customer/address';
import { Customer } from 'src/types/customer/customer';
import { Modals } from 'src/types/Modals';
import { extractResponseStatus, isNotFoundStatus } from 'src/utils/errorStatusChecks';
import getOrderedCustomerPhoneNumber from 'src/utils/getters/getOrdredCustomerPhoneNumber';
import { isBirthdayCheckConfirmed } from 'src/utils/lastBirthdayCheck';
import { reformatBirthdayInResponse } from 'src/utils/mappers/reformatBirthdayInResponse';
import { setOriginalCustomerInfoInSessionStorage } from 'src/utils/originalCustomerInfo';
import isCustomerLocked from 'src/utils/validators/isCustomerLocked';
import { getCheckedDeliveryAddresses } from 'src/utils/validators/isPackstationPostNumberValid';


export function* fetchCustomerByNumberSaga(action: PayloadAction<string>) {
  const agentUsername = yield select(appSelectors.getAgentUsername);
  const loginMethod = yield select(appSelectors.getLoginMethod);

  const loginEventPayload: LoginEventPayload = {
    c_agentId: agentUsername,
    loginMethod: loginMethod
  };
  try {
    yield put(fetchCustomerInProgress());
    const response: Customer = yield call(findCustomerByExternalId, action.payload);

    setOriginalCustomerInfoInSessionStorage(response);
    const customer = yield call(checkAndLoadLocalCustomerInfo, response);

    const currentLoggedInCustomer: Customer | undefined = yield select(customerInfoSelectors.getCustomer);
    const currentCountry = yield select(appSelectors.getCurrentCountry);

    if (customer.billingAddress?.countryCode !== currentCountry) {
      yield call(handleCustomerCountryMismatch, action.payload, loginEventPayload);
      return;
    }

    if (isCustomerLocked(customer)) {
      yield call(handleBlockedCustomer, customer);
      yield put(trackUserFailureLoginsEvent({
        ...loginEventPayload,
        errorContext: ErrorContext.LOGIN,
        errorMessage: `customer ${action.payload} is locked`
      }));
      return;
    }

    yield put(resetCustomerPinState(reformatBirthdayInResponse(customer)));

    // TODO: check with PO this case if we should destroy current customer whenever number is typed in the input
    if (currentLoggedInCustomer?.externalCustomerId === customer.externalCustomerId) {
      yield call(handleCustomerReauthentication, loginEventPayload);
      return;
    }

    if(fetchCustomerForWaitlist.type === action.type) {
      return;
    }

    yield call(handleCustomerPinChallengeOrBirthdayCheck, customer);

    yield put(setPinCheckModalNextStep(Modals.consentManagement)); // TODO: move this to resetCustomerPinState
  } catch (err) {
    yield call(handleFetchCustomerError, err, action.payload, loginEventPayload);
  } finally {
    yield put(fetchCustomerFinished());
  }
}

export function* findCustomerByExternalId(externalCustomerId: string) {
  const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
  const response: Customer = yield call(apiCustomer.findCustomerById, externalCustomerId.replace(/ /g, ''));
  response.deliveryAddresses.unshift(buildInvoiceAddressFromCustomer(response));
  return {
    ...response,
    ...getOrderedCustomerPhoneNumber(response.phoneNumbers),
    deliveryAddresses: getCheckedDeliveryAddresses(response.deliveryAddresses),
  };
}

export function buildInvoiceAddressFromCustomer(customer: Customer): DeliveryAddress {
  const billingAddress = customer.billingAddress!;
  return {
    id: customer.billingAddress?.id ?? customer.id,
    externalAddressId: customer.externalCustomerId,
    type: DeliveryAddressType.Postal,
    firstName: customer.firstName,
    lastName: customer.lastName,
    salutation: customer.salutation!,
    addressAddition: billingAddress.addressAddition,
    city: billingAddress.city,
    countryCode: billingAddress.countryCode,
    careOf: billingAddress.careOf,
    street: billingAddress.street,
    streetNumber: billingAddress.streetNumber,
    zipCode: billingAddress.zipCode,
    fromInvoiceAddress: true,
  };
}

export default function* fetchCustomerByNumberWatcher() {
  yield takeLatest(fetchCustomer.type, fetchCustomerByNumberSaga);
  yield takeLatest(fetchCustomerForWaitlist.type, fetchCustomerByNumberSaga);
}

function* handleCustomerCountryMismatch(customerId: string, loginEventPayload: LoginEventPayload) {
  yield put(openModal(Modals.customerWrongCountry));
  yield put(trackUserFailureLoginsEvent({
    ...loginEventPayload,
    errorContext: ErrorContext.LOGIN,
    errorMessage: `Customer ${customerId} country is wrong`
  }));
}

function* handleCustomerReauthentication(loginEventPayload: LoginEventPayload) {
  yield put(birthDayCheckConfirmed());
  yield put(trackUserLoginEvent({
    ...loginEventPayload,
    loginVerificationOption: 'reauthenticate_customer'
  }));
}

function* handleFetchCustomerError(err: Exception, customerId: string, loginEventPayload: LoginEventPayload) {
  if (isNotFoundStatus(err)) {
    yield put(openModal(Modals.customerSearch));
    yield put(trackUserFailureLoginsEvent({
      ...loginEventPayload,
      errorContext: ErrorContext.LOGIN,
      errorMessage: `Customer ${customerId} not found; redirecting to customer search form`
    }));
    yield put(setLoginMethod(LoginMethod.ADVANCED_CUSTOMER_SEARCH));
  } else {
    yield put(logErrorEvent({ message: `Could not authenticate customer ${customerId}`, err }));
    yield put(trackUserFailureLoginsEvent({
      ...loginEventPayload,
      errorContext: ErrorContext.LOGIN,
      errorMessage: `Could not authenticate customer ${customerId} due to: ${err.value}`
    }));
  }
  yield put(customerError(extractResponseStatus(err) ?? 400));
  yield put(trackUserFailureLoginsEvent({
    ...loginEventPayload,
    errorContext: ErrorContext.LOGIN,
    errorMessage: `Could not find customer ${customerId} (status: ${extractResponseStatus(err) ?? 400})`
  }));
}

function* handleCustomerPinChallengeOrBirthdayCheck(customer: Customer) {
  if (customer.pinChallenge !== undefined && config.env.enableCustomerPinFeature) {
    yield put(openModal(Modals.pinChallengeModal));
  } else {
    if(isBirthdayCheckConfirmed(customer.externalCustomerId)){
      yield put(birthDayCheckConfirmed());
    } else {
      yield put(openModal(Modals.birthdayCheck));
    }

  }
  yield put(setPinCheckModalNextStep(Modals.consentManagement));
}
