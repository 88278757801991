import { DeliveryAddress, DeliveryAddressType } from 'src/types/customer/address';


function isPackstationPostNumberValid(packstationPostNumber: string): boolean {

  if (!packstationPostNumber || /[a-z]/i.test(packstationPostNumber)) {
    return false;
  }
  const  PACKSTATION_POST_NUMBER_LAST_DIGIT = 9;

  const postNumberWithFullDigit = packstationPostNumber.padStart(10, '0');
  const regex = /(\d{10})/i;
  const match = regex.exec(postNumberWithFullDigit);
  if (match === null) {
    return false;
  }
  const matchedPostNumber = match[1];

  let oddDigitsSum = 0;
  let evenDigitsSum = 0;
  [...matchedPostNumber].forEach((digit, index) => {
    if (index === 9) {return;}
    if (index % 2 !== 0) {oddDigitsSum += parseInt(digit);}
    else {evenDigitsSum += parseInt(digit);}
  });

  const totalChecks = (oddDigitsSum * 9) + (evenDigitsSum * 4);
  const checkDigit = roundToNextMultipleOf10(totalChecks) - totalChecks;

  return checkDigit === +matchedPostNumber.charAt(PACKSTATION_POST_NUMBER_LAST_DIGIT);

}

function roundToNextMultipleOf10(number: number): number {
  const remainder = number % 10;
  return remainder === 0 ? number : number + (10 - remainder);
}

export function getCheckedDeliveryAddresses(deliveryAddresses: DeliveryAddress[]): DeliveryAddress[] {
  return deliveryAddresses.map((address) => {
    if (address.type === DeliveryAddressType.Packstation) {
      const isPostNumberInvalid = isPackstationPostNumberValid(address.postNumber);
      return { ...address, invalid: !isPostNumberInvalid };
    } else if (address.type === DeliveryAddressType.DHLAddress) {
      const isPostNumberInvalid = isPackstationPostNumberValid(address.servicePoint.post);
      return { ...address, invalid: !isPostNumberInvalid };
      
    } else {
      return address;
    }
  });
}

export default isPackstationPostNumberValid;
